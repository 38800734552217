<template>
  <div class="footer">
    <div class="container" style="padding-top: 20px;">
      <div class="row">
        <div class="col-lg-3 col-sm-3 col-12 marginb">
          <img src="../../assets/lanxuelogo.png"  width="180"/>
        </div>
        <div class="col-lg-7 col-sm-7 col-12 marginb">
          <div class="row">
          <div class="col-sm-5 col-12">联系人：胡经理</div>
          <div class="col-sm-7 col-12">邮箱：<a href="mailto:hujiaqi@dllxai.com">hujiaqi@dllxai.com</a></div>
        </div>
        <div class="row">
          <div class="col-sm-5 col-12">电话：<a href="tel:13124105127">13124105127</a></div>
          <div class="col-sm-5 col-12">地址：大连市高新园区火炬路56A集电大厦22层</div>
          </div>
        </div>
        <div class="col-lg-2 col-sm-2 col-12 ewm" >
          <img src="../../assets/gzh.jpg"  width="80"/>
        </div>
      </div>
    </div>
    <div class="container" >
      <p  class="copyright">Copyright © 2023 . All Rights Reserved. 大连蓝雪智能科技有限公司 版权所有 <a href="https://beian.miit.gov.cn" target="_blank">辽ICP备2022010164号-1</a></p>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import "../../style/base.scss";
a{
    color:#ffffff !important
  }
.copyright{
  color: $white-opacity;
  font-size: 12px; margin-bottom: 0px; margin-top: 24px; text-align: center;
  a{
    color:$white-opacity !important
  }
}
.marginb{
  margin-bottom: 16px;
}
.padding0 {
  padding: 0px !important;
}
.footer {
  text-align: left;
  background: #000000;
  color: #fff;
  padding-bottom: 16px;
  a{
    color: #fff;
  }
}
.ewm{ text-align: right;}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
}

@media only screen and (min-width: 577px) and (max-width: 767px) {
}
@media only screen and (max-width: 576px) {
  .ewm{ text-align: center;}
}
</style>
<script>
export default {
  data() {
    return {
    };
  },
  mounted() {},
  methods: {},
};
</script>